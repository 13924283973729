import { onDomReady } from 'utils/onDomReady'

const LIST_HORIZONTAL_CLASSNAME = 'similar-sources-carousel--horizontal'
const SIMPLE_CARDS_CLASSNAME = 'similar-sources-carousel--simple-cards'

let swiperInstance

const getSourcesItems = () =>
  Array.from(
    document.querySelectorAll(
      '.similar-sources-carousel__list .integration-card'
    )
  )

function showElementsHorizontally() {
  if (window.matchMedia('(max-width: 749px)').matches) {
    return getSourcesItems().length < 3
  }
  if (window.matchMedia('(max-width: 1023px)').matches) {
    return getSourcesItems().length < 5
  }
  return getSourcesItems().length < 7
}

function initializeSlider() {
  const carouselElement = document.querySelector(
    '.similar-sources-carousel__slider'
  )
  const carouselElementWrap = document.querySelector(
    '.similar-sources-carousel'
  )
  if (!carouselElement) return

  if (showElementsHorizontally()) {
    carouselElementWrap.classList.add(LIST_HORIZONTAL_CLASSNAME)
    if (swiperInstance) {
      swiperInstance.destroy()
      swiperInstance = null
    }

    return
  }

  import('swiper').then(({ default: Swiper, Navigation, Grid }) => {
    Swiper.use([Navigation, Grid])

    const isSimpleCards = carouselElementWrap.classList.contains(
      SIMPLE_CARDS_CLASSNAME
    )

    const swiperConfig = {
      slidesPerView: 1.05,
      navigation: {
        nextEl: '.similar-sources-carousel__control_next',
        prevEl: '.similar-sources-carousel__control_prev'
      },
      grid: {
        fill: isSimpleCards ? 'row' : 'column',
        rows: isSimpleCards ? 1 : 2
      },
      breakpoints: {
        750: {
          slidesPerView: 2.2,
          grid: {
            rows: 2
          }
        },
        1024: {
          slidesPerView: 3
        }
      }
    }

    swiperInstance = new Swiper(carouselElement, swiperConfig)

    carouselElementWrap.classList.remove(LIST_HORIZONTAL_CLASSNAME)
  })
}

onDomReady(() => {
  initializeSlider()
  window.addEventListener('resize', initializeSlider)
})
